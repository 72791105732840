<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import {
  SuiButton,
  SuiForm,
  SuiFormField,
  SuiIcon,
  SuiInput,
  SuiHeader,
  SuiGrid,
  SuiGridColumn,
  SuiList,
  SuiListItem,
  SuiSegment,
  SuiMessage,
} from "@symbx/vue-fomantic-ui";
import "fomantic-ui-css/components/segment.min.css";
import "fomantic-ui-css/components/grid.min.css";
import "fomantic-ui-css/components/header.min.css";
import "fomantic-ui-css/components/form.min.css";
import "fomantic-ui-css/components/input.min.css";
import "fomantic-ui-css/components/icon.min.css";
import "fomantic-ui-css/components/button.min.css";
import "fomantic-ui-css/components/list.min.css";
import "fomantic-ui-css/components/message.min.css";
import "fomantic-ui-css/components/loader.min.css";
import { restoreAuth, saveAuth, formatPhone } from "@/utils";
import { signIn, verify, setToken } from "@/api";
import config from "@/assets/config.json";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();
const username = ref("");
const password = ref("");
const secure = ref(false);
const loading = ref(false);
const canEnter = computed(
  () => username.value.length > 0 && password.value.length > 0,
);
const techSupport = ref(config.support);
const copyrightYear = ref(config.copyrightStart);
const currentYear = computed(() => new Date().getFullYear());
const authorized = computed(() => store.getters.authorized);
const error = ref(0);

async function startApp() {
  await store.dispatch("loadSettings");
  // Pass to another "thread"
  setTimeout(() => {
    store.dispatch("loadVehicles");
    store.dispatch("loadCompanies");
    store.dispatch("loadZoneTypes");
    store.dispatch("loadZones");
    store.dispatch("loadDrivers");
    store.dispatch("loadReports");
  }, 1);
  setTimeout(() => {
    store.dispatch("loadStatuses");
  }, 10);
}

async function enter() {
  loading.value = true;
  try {
    let response = await signIn(username.value, password.value, false);
    setToken(response.data.accessToken);
    saveAuth(response.data.accessToken);
    store.commit("setAuth", {
      token: response.data.accessToken,
      username: response.data.username,
      rights: response.data.rights,
      email: response.data.email,
    });
    await startApp();
  } catch (e) {
    if (!e.isAxiosError || !e.response) {
      error.value = 1;
    } else {
      // Case error type for specific message
      switch (e.response.status) {
        case 401:
          error.value = 2;
          break;
        case 403: // OTP required, need second request
          error.value = -1;
          break;
        default:
          error.value = 1;
          break;
      }
    }
  } finally {
    loading.value = false;
  }
}

onMounted(async () => {
  let token = route.query.tkn || null;
  if (!token) {
    token = restoreAuth();
  } else {
    saveAuth(token);
  }
  // Try to resume session
  if (token) {
    loading.value = true;
    try {
      const response = await verify(token);
      setToken(token);
      store.commit("setAuth", {
        token: token,
        username: response.data.username,
        rights: response.data.rights,
        email: response.data.email,
      });
      await startApp();
    } catch (e) {
      // ignore
      console.error("[Auth] Failed to resume", e);
    } finally {
      loading.value = false;
    }
  }
});
</script>

<template>
  <div class="page">
    <div class="overlay" />
    <sui-grid
      class="base-grid"
      centered
      vertical-align="middle"
      stackable
      v-show="!authorized"
    >
      <sui-grid-column :width="2" style="align-self: end !important">
        <div class="auth-description">
          <span v-t="'label.tech-support'" />
          <sui-list>
            <sui-list-item v-for="num in techSupport" :key="num">
              <a :href="'tel:' + num">{{ formatPhone(num) }}</a>
            </sui-list-item>
          </sui-list>
        </div>
      </sui-grid-column>
      <sui-grid-column class="form-column" :width="12">
        <div class="box">
          <sui-segment class="navi">
            <sui-header as="h2" image text-align="center">
              <img
                src="@/assets/imgs/logo.webp"
                alt="Navitrack"
                title="Navitrack"
                height="44"
              />
              Navitrack
            </sui-header>
            <sui-form @submit.prevent.stop="enter" :loading="loading">
              <sui-form-field :error="error !== 0">
                <sui-input
                  class="navi rounded"
                  v-model="username"
                  :placeholder="$t('field.username')"
                  :title="$t('field.username')"
                  icon="user"
                  icon-position="left"
                  :disabled="loading"
                  :read-only="loading"
                />
              </sui-form-field>
              <sui-form-field :error="error !== 0">
                <div
                  class="ui navi left icon action input rounded"
                  :class="{ disabled: loading }"
                >
                  <sui-icon name="lock" />
                  <input
                    class="navi"
                    v-model="password"
                    :type="secure ? 'text' : 'password'"
                    :placeholder="$t('field.password')"
                    :title="$t('field.password')"
                    icon="lock"
                    icon-position="left"
                    :read-only="loading"
                  />
                  <sui-button
                    type="reset"
                    class="right"
                    @click.prevent.stop="secure = !secure"
                    :title="$t('action.show-password')"
                    icon
                    :disabled="loading"
                  >
                    <sui-icon :name="secure ? 'eye slash' : 'eye'" />
                  </sui-button>
                </div>
              </sui-form-field>
              <sui-form-field>
                <sui-button
                  class="navi rounded"
                  color="olive"
                  fluid
                  labeled
                  icon
                  label-position="right"
                  :disabled="!canEnter"
                  :title="$t('action.enter')"
                >
                  {{ $t("action.enter") }}
                  <sui-icon name="sign in" />
                </sui-button>
              </sui-form-field>
            </sui-form>
            <sui-message basic v-if="error > 0">{{
              $t("msg.error." + error)
            }}</sui-message>
          </sui-segment>
        </div>
      </sui-grid-column>
      <sui-grid-column :width="2" style="align-self: end !important">
        <div class="auth-description">
          {{ $t("label.copyright", [copyrightYear, currentYear]) }}
          <br />
          {{ $t("label.rights-reserved") }}
        </div>
      </sui-grid-column>
    </sui-grid>
  </div>
</template>

<style scoped>
.page {
  height: 100%;
  background-image: url("@/assets/imgs/bg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.page > .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.base-grid {
  margin: 0 !important;
  height: 100%;
}
.form-column > .box {
  max-width: 380px;
  margin: 0 auto;
}
.form-column .header {
  font-size: 3em;
  line-height: 2.2em;
  font-weight: 700;
}
.form-column .header > img {
  width: 1.3em !important;
  margin-top: -0.157em !important;
}
.auth-description,
.auth-description a {
  color: #fff !important;
  text-shadow: 0 0 1px #000;
  text-align: center;
}
.auth-description a {
  font-weight: 700 !important;
}
</style>
