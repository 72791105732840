<script setup>
import {
  SuiModal,
  SuiModalHeader,
  SuiModalContent,
  SuiModalActions,
  SuiButton,
  SuiIcon,
} from "@symbx/vue-fomantic-ui";
import "fomantic-ui-css/components/dimmer.min.css";
import "fomantic-ui-css/components/modal.min.css";
import "fomantic-ui-css/components/button.min.css";
import "fomantic-ui-css/components/icon.min.css";
import { ref, provide, computed } from "vue";

const visible = ref(false);
const title = ref("");
const negative = ref(null);
const positive = ref(null);
const content = ref(null);
const neutral = ref(false);

function call(callback) {
  visible.value = false;
  if (!callback) {
    return;
  }
  callback();
}

function open(text, yes, no = null, inner = null, ignore = false) {
  return new Promise((resolve, reject) => {
    title.value = text;
    neutral.value = ignore;
    if (typeof yes === "string") {
      positive.value = {
        text: yes,
        icon: null,
        callback: () => resolve(true),
      };
    } else {
      positive.value = {
        text: yes.text,
        icon: yes.icon,
        callback: () => resolve(true),
      };
    }
    if (no) {
      if (typeof no === "string") {
        negative.value = {
          text: no,
          icon: null,
          callback: () => reject(false),
        };
      } else {
        negative.value = {
          text: no.text,
          icon: no.icon,
          callback: () => reject(false),
        };
      }
    } else {
      negative.value = null;
    }
    content.value = inner;
    visible.value = true;
  });
}

defineExpose({
  open,
});

const single = computed(() => positive.value && !negative.value);
</script>

<template>
  <sui-modal v-model="visible" :closable="false" size="mini" top-most>
    <sui-modal-header v-text="title" :class="{ padded: content === null }" />
    <sui-modal-content
      v-if="content !== null"
      v-text="content"
      style="height: auto; padding: 8px !important"
    />
    <sui-modal-actions :class="{ single }">
      <sui-button
        v-if="negative"
        :icon="!!negative.icon"
        @click="call(negative.callback)"
        class="a-red"
      >
        <sui-icon v-if="negative.icon" :name="negative.icon" />
        {{ negative.text }}
      </sui-button>
      <sui-button
        v-if="positive"
        :icon="!!positive.icon"
        @click="call(positive.callback)"
        :class="single || neutral ? '' : 'a-green'"
        :fluid="single"
      >
        <sui-icon v-if="positive.icon" :name="positive.icon" />
        {{ positive.text }}
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<style scoped>
.a-red {
  color: red;
}
.a-green {
  color: green;
}
.header {
  text-align: center;
}
.actions.single {
  grid-template-columns: auto;
}
.padded {
  padding: 2.3rem !important;
  padding-bottom: 1.8rem !important;
}
.actions {
  border-top: none !important;
  background-color: #fff !important;
}
.header {
  border-bottom: none !important;
}
</style>
