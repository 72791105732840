import { createI18n } from "vue-i18n";
import messages from "@intlify/unplugin-vue-i18n/messages";

const timeFormat = {
  short: {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  },
  long: {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  },
  full: {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  },
  date: {
    month: "short",
    day: "2-digit",
  },
  time: {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  },
  num_date: {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  },
};

export default createI18n({
  legacy: false,
  warnHtmlMessage: "off",
  globalInjection: true,
  locale: "en",
  fallbackLocale: "en",
  availableLocales: ["en", "uk", "ru"],
  messages: messages,
  datetimeFormats: {
    en: timeFormat,
    ru: timeFormat,
    uk: timeFormat,
  },
});
