import { createApp } from "vue";
import "fomantic-ui-css/components/reset.min.css";
import "fomantic-ui-css/components/site.min.css";
import "./style.css";
import App from "@/app.vue";
import router from "@/router";
import { store } from "@/store";
import i18n from "@/lang";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBolt,
  faGasPump,
  faToggleOn,
  faDoorClosed,
  faUser,
  faArrowsSpin,
  faSignal,
  faSnowflake,
  faKey,
  faLocationDot,
  faGauge,
  faSquareParking,
  faTemperatureHalf,
  faSatellite,
  faTriangleExclamation,
  faCompass,
  faInfo,
  faBriefcase,
  faMicrochip,
  faPhone,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
library.add(
  faBolt,
  faGasPump,
  faToggleOn,
  faDoorClosed,
  faUser,
  faArrowsSpin,
  faSignal,
  faSnowflake,
  faKey,
  faLocationDot,
  faGauge,
  faSquareParking,
  faTemperatureHalf,
  faSatellite,
  faTriangleExclamation,
  faCompass,
  faInfo,
  faBriefcase,
  faMicrochip,
  faPhone,
  faQuestion,
  faClock,
);
import { init as initSentry } from "@sentry/vue";

const app = createApp(App);
initSentry({
  app,
  dsn: "https://1597fb943eb749dbb6c0ab592150a50a@o4504490851041280.ingest.us.sentry.io/4504490852548608",
  integrations: [],
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
});
app.use(router);
app.use(store);
app.use(i18n);
app.mount("#app");
