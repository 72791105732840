<script setup>
import {
  SuiModal,
  SuiModalHeader,
  SuiModalContent,
  SuiModalActions,
  SuiButton,
  SuiIcon,
} from "@symbx/vue-fomantic-ui";
import "fomantic-ui-css/components/dimmer.min.css";
import "fomantic-ui-css/components/modal.min.css";
import "fomantic-ui-css/components/button.min.css";
import "fomantic-ui-css/components/icon.min.css";
import { ref, provide, computed } from "vue";

const visible = ref(false);
const title = ref("");
const message = ref("");
const content = ref("");
const callback = ref(null);

function open(text, msg, value = "") {
  return new Promise((resolve, reject) => {
    title.value = text;
    message.value = msg;
    content.value = value;
    callback.value = {
      resolve,
      reject,
    };
    visible.value = true;
  });
}

defineExpose({
  open,
});

function cancel() {
  if (callback.value && callback.value.reject) {
    callback.value.reject();
    visible.value = false;
  }
}

function ok() {
  if (callback.value && callback.value.resolve) {
    callback.value.resolve(content.value);
    visible.value = false;
  }
}
</script>

<template>
  <sui-modal v-model="visible" :closable="false" size="mini" top-most>
    <sui-modal-header v-text="title" />
    <sui-modal-content style="height: auto; padding: 8px !important">
      {{ message }}
      <input v-model="content" class="prompt-input" />
    </sui-modal-content>
    <sui-modal-actions>
      <sui-button @click="cancel" class="a-red">
        <sui-icon name="cancel" />
        {{ $t("action.cancel") }}
      </sui-button>
      <sui-button @click="ok" class="'a-green'">
        <sui-icon name="check mark" />
        {{ $t("action.ok") }}
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<style scoped>
.a-red {
  color: red;
}
.a-green {
  color: green;
}
.header {
  text-align: center;
}
.actions.single {
  grid-template-columns: auto;
}
.padded {
  padding: 2.3rem !important;
  padding-bottom: 1.8rem !important;
}
.actions {
  border-top: none !important;
  background-color: #fff !important;
}
.header {
  border-bottom: none !important;
}
.content {
  font-weight: bold;
  text-align: center;
  color: #575757;
}
.prompt-input {
  background-color: #eeeef0;
  border: none;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 5px;
  font-size: 12px;
  outline: none !important;
  padding: 5px;
  width: calc(100% - 16px);
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
}
</style>
