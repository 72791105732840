import axios from "axios";

/**
 * Enum with rights names
 */
export const Rights = {
  ReadReports: "READ_REPORTS",
  PrintReports: "PRINT_REPORTS",
  Drivers: "DRIVERS",
  EditZones: "CRUD_ZONE",
  ViewZones: "VIEW_ZONES",
  EditVehicle: "EDIT_VEHICLE",
  CRUDVehicles: "CRUD_VEHICLE",
  WaybillManage: "WAYBILL_MANAGE",
  WaybillView: "WAYBILL_VIEW",
  UserLayers: "VIEW_USER_LAYER",
};

/**
 * List of default columns for vehicle list
 */
export const DefaultColumns =
  "ICON,NAME,LAST_DATA_DATE,SPEED,FUEL_LEVEL,IGNITION";

/**
 * Vehicle list column parameters
 */
export const ListColumns = [
  {
    key: "SHOW",
    size: "34px",
    i: 1,
    s: () => 0,
    l: function () {
      return 0;
    },
  },
  {
    key: "COMPANY",
    size: "1.5fr",
    i: 2,
    s: (o) => o.cm,
    l: (o) => o.cm,
  },
  {
    key: "IGNITION",
    size: "1fr", // 1fr
    i: 3,
    s: (o) => o.v,
    l: function (o, t) {
      // return t.apply(this, ["label." + (o.ignition ? "on" : "off")]);
      return t("label." + (o.v ? "on" : "off"));
    },
  },
  {
    key: "FUEL_LEVEL",
    size: "1fr", // 1fr
    i: 4,
    s: (o) => o.f[0] + o.f[1] + o.f[2],
    l: function (o, t) {
      // return t.apply(this, ["label.l", [o.fuel1 + o.fuel2 + o.fuel3]]);
      return t("label.l", [o.f[0] + o.f[1] + o.f[2]]);
    },
  },
  {
    key: "FUEL_LEVEL_PERCENTS",
    size: "1fr",
    i: 5,
    s: (o) => (((o.f[0] + o.f[1] + o.f[2]) / (o.k || 1)) * 100).toFixed(1) * 1,
    l: function (o) {
      return (
        (((o.f[0] + o.f[1] + o.f[2]) / (o.k || 1)) * 100).toFixed(1) * 1 + "%"
      );
    },
  },
  {
    key: "FUEL_SPACE",
    size: "1fr",
    i: 6,
    s: (o) => o.k - (o.f[0] + o.f[1] + o.f[2]),
    l: function (o, t) {
      return t("label.l", [o.k - (o.f[0] + o.f[1] + o.f[2])]);
    },
  },
  {
    key: "FUEL_VOLUME",
    size: "1fr",
    i: 7,
    s: (o) => o.k,
    l: function (o, t) {
      return t("label.l", [o.k]);
    },
  },
  {
    key: "DOORS_STATUS",
    size: "1fr",
    i: 8,
    s: (o) => o.h,
    l: function (o, t) {
      return t("label." + (o.h ? "open" : "closed"));
    },
  },
  {
    key: "ALARM",
    size: "1fr",
    i: 9,
    s: (o) => o.w,
    l: function (o, t) {
      return t("label." + (o.w ? "on" : "off"));
    },
  },
  {
    key: "HOU",
    size: "1fr",
    i: 10,
    s: (o) => o.h,
    l: function (o, t) {
      return t("label." + (o.h ? "on" : "off"));
    },
  },
  {
    key: "TEMPERATURE",
    size: "1fr",
    i: 11,
    s: (o) => o.t,
    l: function (o, t) {
      return t("label.t", [o.t]);
    },
  },
  {
    key: "ICON",
    size: "34px",
    i: 12,
    s: (o) => o.m,
    l: () => "",
  },
  {
    key: "NAME",
    size: "1.8fr",
    i: 13,
    s: (o) => o.n,
    l: (o) => o.n,
  },
  {
    key: "LAST_DATA_DATE",
    size: "1fr",
    i: 14,
    s: (o) => o.j,
    l: function (o, t, d) {
      return d(o.j, "full").replace(",", "");
    },
  },
  {
    key: "SPEED",
    size: "1fr",
    i: 15,
    s: (o) => (o.s === "P" ? 0 : parseInt(o.s)),
    l: function (o, t) {
      return t("label.kmh", [o.s === "P" ? 0 : parseInt(o.s)]);
    },
  },
];

/**
 * Possible event types
 */
export const PopupType = {
  VehicleInfo: 0,
  EventInfo: 1,
};

/**
 * Vehicle list parameters formatted as object
 */
export const ListColumnsObject = ListColumns.reduce((a, c) => {
  a[c.key] = c;
  return a;
}, {});

/**
 * Parametes for api
 * Module prefix: /ws
 * Time ti timeout: 2h
 */
const api = axios.create({
  baseURL: "/ws/",
  timeout: 2 * 60 * 60 * 1000,
});

/**
 * Authorize user
 *
 * @param {String} username Username
 * @param {String} password Password
 * @param {Boolean} rememberMe Remember user
 * @returns Response object
 */
export function signIn(username, password, rememberMe = false) {
  return api.post("/api/auth/signin", {
    username,
    password,
    rememberMe,
  });
}

/**
 * Check user sesion
 *
 * @param {String} token Token string
 * @returns Response object
 */
export function verify(token) {
  return api.get("/api/auth/verify", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

/**
 * Set token string into api parameters
 *
 * @param {String} token Token string
 */
export function setToken(token) {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    api.defaults.headers.common = undefined;
  }
}

/**
 * Get user settings
 *
 * @returns Response object
 */
export function getSettings() {
  return api.post("user/settings/getmain");
}

/**
 * Get vehicle statuses
 *
 * @param {String} cols List of columns, joined with comma
 * @returns Response object
 */
export function getStatuses(cols) {
  return api.get(`vehicles-short?cols=${cols}`);
}

/**
 * Get companies list
 *
 * @returns Response object
 */
export function getCompanies() {
  return api.get("getcompanies");
}

/**
 * Get vehicles list
 *
 * @returns Response object
 */
export function getVehicles() {
  return api.get("allvehicles");
}

/**
 * Get zones list
 *
 * @returns Response object
 */
export function getZones() {
  return api.get("zones/get");
}

/**
 * Get zone types list
 *
 * @returns Response object
 */
export function getZoneTypes() {
  return api.get("zones/get-types");
}

/**
 * Get current vehicle status info
 *
 * @param {Number} id Vehicle id
 * @returns
 */
export function getVehicleInfo(id) {
  return api.get(`vehicle/info/${id}`);
}

/**
 * Set time for given date
 *
 * @param date Date
 * @param h Hours
 * @param m Minutes
 * @param s Seconds
 * @param ms Milliseconds
 * @returns {Date}
 */
export function setDateTime(date, h, m, s, ms) {
  date.setHours(h);
  date.setMinutes(m);
  date.setSeconds(s);
  date.setMilliseconds(ms);
  return date;
}

/**
 * Get start of day for given date
 *
 * @param date Date
 * @returns {Date}
 */
export function setDayBegin(date) {
  return setDateTime(date, 0, 0, 0, 0);
}

/**
 * Get end of day for given date
 *
 * @param date Date
 * @returns {Date}
 */
export function setDayEnd(date) {
  return setDateTime(date, 23, 59, 59, 999);
}

/**
 * Get track of vehicle for given period
 *
 * @param vehicle Vehicle
 * @param start Period start
 * @param end Period end
 * @param events Events list
 * @returns {*}
 */
export function getTrack(vehicle, start, end, events = []) {
  return api.post("track/get", {
    vehicleId: vehicle,
    start,
    end,
    eventIds: events,
  });
}

/**
 * Get vehicle list by company id
 *
 * @param id Company ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getVehiclesByCompany(id) {
  return api.get("vehiclesbycompany/" + id);
}

/**
 * Get drivers list
 *
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getDrivers() {
  return api.get("drivers/get");
}

/**
 * Get critical events
 *
 * @param id Get only newer that given ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getEvents(id = 0) {
  if (isNaN(id)) {
    id = 0;
  }
  return api.get(id > 0 ? `events/${id}` : "events");
}

/**
 * Get vehicle history for specific date
 * @param id Vehicle ID
 * @param date Date
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getHistory(id, date) {
  return api.get(`track/daily/${id}/${date}`);
}

/**
 * Get user reports
 *
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getUserReports() {
  return api.get("reports");
}

/**
 * Get description dictionary
 *
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getDescriptionsDictionary() {
  return api.get("descriptions");
}

/**
 * Get map of vehicle descriptions
 *
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getVehicleDescriptions() {
  return api.get("descriptionlist");
}

/**
 * Calculate CRC16 for given string
 * @param str {String} - String
 * @returns {number} - Checksum
 */
export function CRC16(str) {
  let chk = 0x12345678;
  let len = str.length;
  for (let i = 0; i < len; i++) {
    chk += str.charCodeAt(i) * (i + 1);
  }
  return chk & 0xffffffff;
}

/**
 * Build report with given parameters
 * @param arg Payload for report
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function doReport(arg) {
  const isFile = !["html", "json", "json2"].includes(arg.type);
  return api.post(isFile ? "doreportfile" : "doreport", arg, {
    timeout: 2 * 60 * 60 * 1000,
    responseType: isFile ? "arraybuffer" : "json",
  });
}

/**
 * Get saved report preset
 *
 * @param id Report bunch id
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getReportPreset(id) {
  return api.get(`reportpreset/${id}`);
}

/**
 * Save report preset
 * @param id report bunch id
 * @param list preset list
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function setReportPreset(id, list) {
  return api.post(`reportpreset/${id}`, {
    list,
  });
}

/**
 * Change user password
 *
 * @param current Current password
 * @param next New password
 * @param confirm Confirm password
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function changePassword(current, next, confirm) {
  return api.post("user/settings/changepassword", {
    current,
    next,
    confirm,
  });
}

/**
 * Save user settings
 *
 * @param obj Settings object
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function setSettings(obj) {
  return api.post("user/settings/setmain", obj);
}

/**
 * Get chart data (time series) for vehicle
 *
 * @param vehicle Vehicle
 * @param start Period start
 * @param end Period end
 * @param events Events list
 * @param fields Fields list
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getGraphs(vehicle, start, end, events = [], fields = "") {
  const url =
    "trackpoints/get" + (fields.length > 0 ? "?fields=" + fields : "");
  return api.post(url, {
    vehicleId: vehicle,
    start,
    end,
    eventIds: events,
  });
}

/**
 * Get chart data (time series) for vehicle
 *
 * @param vehicle Vehicle
 * @param start Period start
 * @param end Period end
 * @param trackToken Track token
 * @param events Events list
 * @param fields Fields list
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getGraphsV2(
  vehicle,
  start,
  end,
  trackToken,
  events = [],
  fields = "",
) {
  const url =
    "v2/trackpoints/get" + (fields.length > 0 ? "?fields=" + fields : "");
  return api.post(
    url,
    {
      vehicleId: vehicle,
      start,
      end,
      eventIds: events,
    },
    {
      headers: {
        trackToken,
      },
    },
  );
}

/**
 * Get Excel table with details data
 * @param vehicle Vehicle Id
 * @param start Period start
 * @param end Period end
 * @param fields Fields list
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getDetailsFile(vehicle, start, end, fields = "") {
  const url =
    "trackpoints/download" + (fields.length > 0 ? "?fields=" + fields : "");
  return api.post(
    url,
    {
      vehicleId: vehicle,
      start,
      end,
      eventIds: [],
    },
    {
      timeout: 2 * 60 * 60 * 1000,
      responseType: "arraybuffer",
    },
  );
}

/**
 * Get maintenance list (templates list)
 *
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getMaintenanceList() {
  return api.get("maintenance/list");
}

/**
 * Save maintenance template
 *
 * @param maintenance Maintenance object
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function saveMaintenance(maintenance) {
  return api.post("maintenance/save", maintenance);
}

/**
 * Delete maintenance template
 *
 * @param id Maintenance template id
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function removeMaintenance(id) {
  return api.delete("maintenance/remove/" + id);
}

/**
 * Get report bunch info
 *
 * @param id Bunch ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getReportBunch(id) {
  return api.get(`reportbunch/${id}`);
}

/**
 * Remove report bunch
 *
 * @param id Bunch ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function removeReport(id) {
  return api.delete(`reportbunch/${id}`);
}

/**
 * Save existing or new report bunch
 *
 * @param data Report bunch data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function saveReportBunch(data) {
  return api.post(`reportbunch`, data);
}

/**
 * Get driver
 *
 * @param id Driver ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getDriver(id) {
  return api.get("drivers/" + id);
}

/**
 * Save driver
 *
 * @param driver Driver object
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function saveDriver(driver) {
  return api.post("drivers/save", driver);
}

/**
 * Remove driver
 *
 * @param id Driver ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function removeDriver(id) {
  return api.delete("drivers/" + id);
}

/**
 * Get vehicle main parameters
 *
 * @param id Vehicle ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getVehicleMainSettings(id) {
  return api.get("/vehicle/settings/getmain?vehicleId=" + id);
}

/**
 * Get vehicle common parameters
 *
 * @param id Vehicle ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getVehicleCommonSettings(id) {
  return api.get("/vehicle/settings/getcommon?vehicleId=" + id);
}

/**
 * Get possible vehicle template fields
 *
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getVehicleTemplateDict() {
  return api.get("infra/vehicleinfofields");
}

/**
 * Get setted vehicle template fields
 *
 * @param id Vehicle ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getVehicleTemplate(id) {
  return api.get("vehicle/settings/getinfotemplate?vehicleId=" + id);
}

/**
 * Get vehicle events settings
 *
 * @param id Vehicle ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getVehicleEventSettings(id) {
  return api.get(`getvea/${id}`);
}

/**
 * Lock vehicle engine
 *
 * @param vehicle Vehicle ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function lockVehicle(vehicle) {
  return api.get("vehicle/lock/" + vehicle);
}

/**
 * Unlock vehicle engine
 *
 * @param vehicle Vehicle ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function unlockVehicle(vehicle) {
  return api.get("vehicle/unlock/" + vehicle);
}

/**
 * Get maintenance list for vehicle
 *
 * @param id Vehicle ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getVehicleMaintenanceList(id) {
  return api.get("maintenance/vehicle/" + id);
}

/**
 * Get maintenance journal of vehicle
 *
 * @param vehicle Vehicle ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getMaintenanceJournal(vehicle) {
  return api.get(`maintenance/journal/${vehicle}`);
}

/**
 * Get nearest maintenance for vehicle
 *
 * @param vehicle Vehicle ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getMaintenanceNearest(vehicle) {
  return api.get(`maintenance/nearest/${vehicle}`);
}

/**
 * Remove maintenance from vehicle
 *
 * @param vehicle Vehicle ID
 * @param maintenance Maintenance ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function removeVehicleMaintenance(vehicle, maintenance) {
  return api.delete(`maintenance/vehicle/${vehicle}/${maintenance}`);
}

/**
 * Remove record from maintenance journal
 *
 * @param id Record ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function removeMaintenanceJournal(id) {
  return api.delete("maintenance/journal/" + id);
}

/**
 * Confirm maintenance and add record to journal
 *
 * @param item Maintenance object
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function confirmMaintenance(item) {
  return api.post(`maintenance/vehicle/continue`, item);
}

/**
 * Assign maintenance to vehicle
 *
 * @param maintenance Maintenance link object
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function saveVehicleMaintenance(maintenance) {
  return api.post("maintenance/vehicle/save", maintenance);
}

/**
 * Get point in track by token
 *
 * @param token Track token
 * @param point Point index in track
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getTrackPoint(token, point) {
  return api.post(
    "track/point",
    {},
    {
      headers: {
        trackToken: token,
        pointId: point,
      },
    },
  );
}

/**
 * Get zone object
 * @param id Zone ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getZone(id) {
  return api.get(`zones/${id}`);
}

/**
 * Save main vehicle settings
 *
 * @param obj Main vehicle settings
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function setVehicleMainSettings(obj) {
  return api.post("/vehicle/settings/setmain", obj);
}

/**
 * Save common vehicle settings
 *
 * @param obj Common vehicle settings
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function setVehicleCommonSettings(obj) {
  return api.post("/vehicle/settings/setcommon", obj);
}

/**
 * Save vehicle event activation parameters
 *
 * @param obj Vehicle event activation parameters
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function setVehicleActivation(obj) {
  return api.post("savevea", obj);
}

/**
 * Save vehicle template for popup
 *
 * @param obj Vehicle templates object
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function setVehicleTemplate(obj) {
  return api.post("vehicle/settings/setinfotemplate", obj);
}

/**
 * Save/create zone object
 *
 * @param zone Zone object
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function saveZone(zone) {
  return api.post("zones/save", zone);
}

/**
 * Remove zone
 *
 * @param id Zone ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function deleteZone(id) {
  return api.delete("zones/" + id);
}

/**
 * Create new fuel fillings document
 *
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function createFuelFillDoc() {
  return api.post("fuelfilling/create", {});
}

/**
 * List available fuel filling docs
 *
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function listFuelFillDoc() {
  return api.get("fuelfilling/get");
}

/**
 * Delete given fuel filling document
 *
 * @param id Document ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function deleteFuelFillDoc(id) {
  return api.delete(`fuelfilling/${id}`);
}

/**
 * Save list for fuel filling document
 *
 * @param id Document ID
 * @param list List of fillings
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function saveFuelFillDoc(id, list) {
  return api.patch(`fuelfilling/${id}`, list);
}

/**
 * Get list of fillings for given document
 *
 * @param id Document ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getFuelFills(id) {
  return api.get(`fuelfilling/${id}`);
}

/**
 * Get known fuel types
 *
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getKnownFuel() {
  return api.get(`fuelfilling/knownfuel`);
}

/**
 * Get known fuel station names
 *
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getKnownAzk() {
  return api.get(`fuelfilling/knownazk`);
}

/**
 * Get vehicle engine hours series
 *
 * @param vehicles Vehicle Ids
 * @param start Period start
 * @param end Period end
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getEngineHours(vehicles, start, end) {
  return api.post("track/enginehours", {
    vehicleIds: vehicles,
    start,
    end,
  });
}

/**
 * Save map provider to user settings
 *
 * @param obj Map provider object
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function setMapProvider(obj) {
  return api.post("user/settings/map-provider", obj);
}

/**
 * Request to assign mail
 *
 * @param email EMail to assign
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function assignUserMail(email) {
  return api.post("api/user/mail/assign", {
    email,
  });
}

/**
 * Get user waybill list
 *
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getWaybillList() {
  return api.get("route/list");
}

/**
 * Build route (path to drive) from given control points
 *
 * @param list Control points list (at lease 2 start and destination)
 * @param weight Vehicle gross weight in kg
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getShortestRoute(list, weight = 0) {
  const data = {
    coordinates: list,
  };
  if (weight !== 0) {
    data.weight = weight;
  }
  return api.post("route/coordinates", data);
}

/**
 * Save waybill
 *
 * @param obj Waybill object
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function saveWaybill(obj) {
  return api.post("route/save", obj);
}

/**
 * Get waybill object
 *
 * @param id Waybill ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getWaybill(id) {
  return api.get(`route/${id}`);
}

/**
 * Remove waybill
 *
 * @param id Waybill ID
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function removeWaybill(id) {
  return api.delete(`route/remove/${id}`);
}

/**
 * Get address by coordinates
 *
 * @param lat Latitude
 * @param lng Longitude
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function reverseAddress({ lat, lng }) {
  return api.post("route/address", {
    latitude: lat,
    longitude: lng,
  });
}

/**
 * Get addresses by coordinates
 *
 * @param list List of points {lat, lng}
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function reverseAddresses(list) {
  return api.post(
    "route/addresses",
    list.map((c) => ({
      latitude: c.lat,
      longitude: c.lng,
    })),
  );
}

/**
 * Get report schedule
 *
 * @param id report id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getReportSchedules(id) {
  return api.get(`reportschedule/${id}`);
}

/**
 * Save report schedule
 *
 * @param data report schedule object
 * @returns {Promise<AxiosResponse<any>>}
 */
export function saveReportSchedule(data) {
  return api.post("reportschedule/save", data);
}

/**
 * Remove report schedule
 *
 * @param id - schedule id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function removeReportSchedule(id) {
  return api.delete(`reportschedule/${id}`);
}

/**
 * Get report schedule vehicles
 *
 * @param id schedule id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getReportScheduleVehicles(id) {
  return api.get(`reportvehicle/${id}`);
}

/**
 * Save vehicle list for schedule
 *
 * @param id schedule id
 * @param list vehicle list
 * @returns {Promise<AxiosResponse<any>>}
 */
export function saveReportScheduleVehicles(id, list) {
  return api.post(`reportvehicles/${id}`, list);
}

/**
 * Get schedule mails
 *
 * @param id schedule id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getReportScheduleMail(id) {
  return api.get(`reportmail/${id}`);
}

/**
 * Remove schedule mails
 *
 * @param id schedule id
 * @param mail mail
 * @returns {Promise<AxiosResponse<any>>}
 */
export function removeReportScheduleMail(id, mail) {
  return api.delete(`reportmail/${id}/${mail}`);
}

/**
 * Add mail to schedule mail
 *
 * @param id schedule id
 * @param mail mail
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addReportScheduleMail(id, mail) {
  mail = encodeURI(mail);
  return api.put(`reportmail/${id}/${mail}`, {});
}

/**
 * Save route sheet
 *
 * @param route route object
 * @returns {Promise<AxiosResponse<any>>}
 */
export function saveRouteSheet(route) {
  return api.post("route-sheet/save", route);
}

/**
 * List route sheets
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getRouteSheet() {
  return api.get("route-sheet/list");
}

/**
 * Remove route sheet
 *
 * @param route route id
 * @param vehicle vehicle id
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function removeRouteSheet(id) {
  return api.delete(`route-sheet/${id}`);
}

/**
 * Get user layer list
 *
 * @returns {Promise<*[]|*>}
 */
export async function getUserLayers(name) {
  try {
    const response = await axios.get(
      `/maps/${name.toLocaleLowerCase()}/layers.json?` + Math.random(),
    );
    console.debug("[API] Layers response", response.data);
    if (typeof response.data === "object" && Array.isArray(response.data)) {
      return response.data;
    }
  } catch (e) {
    console.log("No layers", e);
  }
  return [];
}

/**
 * Get user layer GeoJSON
 *
 * @param username username
 * @param file file name
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getUserLayer(username, file) {
  return axios.get(`/maps/${username}/${file}`);
}

/**
 * Set vehicle visible
 *
 * @param id vehicle id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function showVehicle(id) {
  return api.get("vehicle/showweb/" + id);
}

/**
 * Set vehicle invisible
 *
 * @param id vehicle id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function hideVehicle(id) {
  return api.get("vehicle/hideweb/" + id);
}

/**
 * Set vehicle in company visible
 *
 * @param id company id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function showCompany(id) {
  return api.get("vehicle/companyshow/" + id);
}

/**
 * Set vehicle in company invisible
 *
 * @param id company id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function hideCompany(id) {
  return api.get("vehicle/companyhide/" + id);
}

/**
 * Get initial dashboard data
 * @param blocks block types
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getDashboardInit(blocks) {
  return api.get(`dashboard/initial?blocks=${blocks.join(",")}`);
}

/**
 * Get dashboard period data
 * @param data request
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getDashboardData(data) {
  return api.post("dashboard/events", data);
}

/**
 * Save dashboard settings
 * @param data settings
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function setDashboardSettings(data) {
  return api.post("dashboard/setsettings", {
    dashboardDetailsFields: data,
  });
}

export function loadWorkingHours(vehicle) {
  return api.get("vehicle/hours/" + vehicle);
}

export function saveWorkingHours(vehicle, data) {
  return api.post("vehicle/hours/" + vehicle, data);
}

/**
 * Get list of report items in store
 *
 * @returns {*}
 */
export function getReportsV2Store() {
  return api.get("reports/v2/list");
}

/**
 * Get report result from store
 *
 * @param id Report build ID
 * @param type Output format
 * @returns {*}
 */
export function getReportsV2Result(id, type = "json") {
  const isFile = !["html", "json"].includes(type);
  return api.get(`reports/v2/${id}/${type}`, {
    responseType: isFile ? "arraybuffer" : "json",
  });
}

/**
 * Create new request for report build
 *
 * @param payload Report payload
 * @returns {*}
 */
export function requestReportsV2(payload) {
  return api.post("reports/v2/request", payload);
}

/**
 * Delete report build from store
 *
 * @param id Report build ID
 * @returns {*}
 */
export function deleteReportsV2(id) {
  return api.delete(`reports/v2/${id}`);
}

/**
 * Get list of notifications for given user
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getNotifications() {
  return api.get("notification/list");
}

/**
 * Mark notification as readed
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function confirmNotification(id) {
  return api.patch(`notification/${id}/confirm`);
}
