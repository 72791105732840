import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "vuex";
import AuthPage from "@/pages/auth-page.vue";

// Creating router.
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  // linkActiveClass: "active",
  linkExactActiveClass: "active",
  routes: [
    {
      name: "auth",
      path: "/",
      component: AuthPage,
      meta: {
        auth: false,
      },
    },
    {
      path: "/navitrack/:catchAll(.*)",
      redirect: (to) => {
        return { name: "auth" };
      },
    },
    {
      path: "/map",
      component: () => import("@/pages/map-page.vue"),
      meta: {
        auth: true,
      },
      children: [
        {
          name: "map",
          path: "",
          component: () => import("@/pages/map-base-page.vue"),
          meta: {
            auth: true,
          },
        },
        {
          name: "waybill",
          path: "waybill",
          component: () => import("@/pages/waybill-page.vue"),
          meta: {
            auth: true,
          },
        },
      ],
    },
    {
      name: "reports",
      path: "/reports",
      component: () => import("@/pages/reports-page.vue"),
      meta: {
        auth: true,
      },
    },
    {
      name: "dashboard",
      path: "/dashboard",
      component: () => import("@/pages/dashboard-page.vue"),
      meta: {
        auth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    const store = useStore();
    if (!store.getters.authorized) {
      next({
        name: "auth",
      });
      return;
    }
  }
  next();
});

export default router;
