import { createStore } from "vuex";
import {
  DefaultColumns,
  getCompanies,
  getStatuses,
  getVehicles,
  getZones,
  getSettings,
  getZoneTypes,
  getDrivers,
  getEvents,
  getUserReports,
  getVehicleDescriptions,
  getWaybillList,
  getReportsV2Store,
} from "@/api";
import { calculatePolygonArea } from "@/utils.js";
import { getRouteSheet } from "./api";

export const store = createStore({
  state() {
    // Initial app state
    return {
      token: null,
      username: "",
      email: "",
      settings: {},
      rights: [],
      statuses: [],
      companies: [],
      vehicles: [],
      zones: [],
      zoneTypes: [],
      drivers: [],
      popup: null,
      events: [],
      currentVehicle: null,
      reports: [],
      descriptions: [],
      tracks: [],
      waybillPlans: [],
      singleTrack: null,
      currentTrack: null,
      eventPopup: null,
      editZone: null,
      visibleZones: [],
      mapReport: null,
      editWaybill: null,
      waybills: [],
      line: null,
      currentZoom: null,
      reportStore: [],
      reportStoreLimit: 5,
    };
  },
  mutations: {
    setAuth(state, { token, username, rights, email }) {
      console.debug("[Store] Set auth", { token, username, rights, email });
      state.token = token;
      state.username = username;
      state.rights = rights;
      state.email = email;
    },
    clearSession(state) {
      state.session = null;
      state.username = "";
      state.rights = [];
      state.email = "";
    },
    setSettings(state, settings) {
      if (settings.map === null) {
        settings.map = "osm:standard";
      }
      state.settings = settings;
    },
    setRights(state, rights) {
      state.rights = rights;
    },
    setStatuses(state, list) {
      state.statuses = list;
    },
    setCompanies(state, list) {
      state.companies = list;
    },
    updateStatusCompany(state) {
      const companies = state.companies.reduce((a, c) => {
        a[c.id] = c.name;
        return a;
      }, {});
      state.statuses.forEach((s) => {
        s.cm = companies[s.c] || "-";
      });
    },
    setVehicles(state, list) {
      state.vehicles = list;
    },
    setZones(state, list) {
      list.forEach((z) => {
        if (!z.area && z.points) {
          z.area = calculatePolygonArea(z.points, true);
        }
      });
      state.zones = list;
    },
    setZoneTypes(state, list) {
      state.zoneTypes = list;
    },
    setDrivers(state, list) {
      state.drivers = list;
    },
    setAllVehicleVisible(state, val) {
      state.vehicles.forEach((v) => (v.showWeb = val));
    },
    setVehicleVisible(state, { id, val }) {
      const vehicle = state.vehicles.find((v) => (v.id = id));
      if (vehicle) {
        vehicle.showWeb = val;
      } else {
        console.warn("[Store] Failed to find vehicle to change visibility", id);
      }
    },
    setCompanyVisible(state, { id, val }) {
      const list = state.statuses.filter((s) => s.c === id).map((s) => s.i);
      state.vehicles.forEach((v) => {
        if (list.includes(v.id)) {
          v.showWeb = val;
        }
      });
    },
    setPopup(state, popup) {
      state.popup = popup;
    },
    updateEvents(state, list) {
      state.events = state.events.concat(list).sort((a, b) => {
        if (a.time > b.time) {
          return -1;
        }
        if (a.time < b.time) {
          return 1;
        }
        return 0;
      });
    },
    setCurrentVehicle(state, id) {
      if (state.currentVehicle !== id) {
        state.singleTrack = null;
      }
      state.currentVehicle = id;
    },
    setReports(state, list) {
      state.reports = list;
    },
    setDescriptions(state, list) {
      state.descriptions = list;
    },
    setMapProvider(state, provider) {
      state.settings.map = provider;
    },
    setTracks(state, tracks) {
      state.eventPopup = null;
      state.tracks = tracks;
      if (tracks.length < 1) {
        state.currentTrack = null;
      }
    },
    setSingleTrack(state, track) {
      state.singleTrack = track;
    },
    setCurrentTrack(state, track) {
      state.eventPopup = null;
      state.currentTrack = track;
    },
    tryUpdate(state, data) {
      const vehicle = state.statuses.find((s) => s.i === data.vehicle);
      if (vehicle && vehicle.j < data.time) {
        console.debug(
          "[Store] Force marker update",
          vehicle.j,
          "->",
          data.time,
          ",",
          vehicle.x,
          "->",
          data.x,
          ",",
          vehicle.y,
          "->",
          data.y,
        );
        vehicle.j = data.time;
        vehicle.x = data.x;
        vehicle.y = data.y;
      } else {
        console.debug("[Store] Marker is newer");
      }
    },
    setEventPopup(state, event) {
      state.eventPopup = event;
    },
    setEditZone(state, zone) {
      console.debug("[Store] Editing zone", zone);
      state.editZone = zone;
    },
    setVisibleZones(state, list) {
      state.visibleZones = list;
    },
    setMapReport(state, report) {
      state.mapReport = report;
    },
    setEditWaybill(state, waybill) {
      state.editWaybill = waybill;
    },
    setWaybills(state, list) {
      state.waybills = list;
    },
    setLine(state, line) {
      state.line = line;
    },
    setWaybillPlan(state, list) {
      state.waybillPlans = list;
    },
    setZoom(state, val) {
      state.currentZoom = val;
    },
    updateTrackWeight(state, { id, weight }) {
      const t = state.tracks.find((t) => t.sub === id);
      if (t) {
        console.debug("Set track weight", id, weight);
        t.weight = weight;
        state.tracks = state.tracks.filter((t) => t.sub !== id).concat([t]);
      } else {
        console.warn("Track not found", id, weight);
      }
    },
    updateTrackColor(state, { id, color }) {
      const t = state.tracks.find((t) => t.sub === id);
      if (t) {
        console.debug("Set track color", id, color);
        t.color.solid = color;
        state.tracks = state.tracks.filter((t) => t.sub !== id).concat([t]);
      } else {
        console.warn("Track not found", id, color);
      }
    },
    setReportStore(state, list) {
      state.reportStore = list;
    },
    setReportState(state, { id, progress }) {
      if (progress === -3) {
        state.reportStore = state.reportStore.filter((r) => r.id !== id);
      } else {
        let item = state.reportStore.find((r) => r.id === id);
        if (item) {
          item.state = progress;
        }
      }
    },
    addReportStoStore(state, item) {
      if (item.progress !== -3) {
        state.reportStore = state.reportStore
          .filter((r) => r.id !== item.id)
          .concat([item]);
      }
    },
    removeReportStore(state, id) {
      state.reportStore = state.reportStore.filter((r) => r.id !== id);
    },
  },
  actions: {
    async loadSettings(ctx) {
      try {
        const res = await getSettings();
        ctx.commit("setSettings", res.data);
        return 0;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          ctx.commit("clearSession");
          return 403;
        }
        return e.response ? e.response.status : 999;
      }
    },
    async loadStatuses(ctx) {
      try {
        const res = await getStatuses(
          getSettingFallback(
            ctx.state.settings,
            "vehicleTableColumns",
            DefaultColumns,
          ),
        );
        ctx.commit("setStatuses", res.data);
        ctx.commit("updateStatusCompany");
        return 0;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          ctx.commit("clearSession");
          return 403;
        }
        return e.response ? e.response.status : 999;
      }
    },
    async loadCompanies(ctx) {
      try {
        const res = await getCompanies();
        ctx.commit(
          "setCompanies",
          res.data.map((c) => {
            return {
              id: parseInt(c.key),
              name: c.value,
            };
          }),
        );
        ctx.commit("updateStatusCompany");
        return 0;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          ctx.commit("clearSession");
          return 403;
        }
        return e.response ? e.response.status : 999;
      }
    },
    async loadVehicles(ctx) {
      try {
        const res = await getVehicles();
        ctx.commit("setVehicles", res.data);
        return 0;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          ctx.commit("clearSession");
          return 403;
        }
        return e.response ? e.response.status : 999;
      }
    },
    async loadZones(ctx) {
      try {
        const res = await getZones();
        ctx.commit("setZones", res.data);
        return 0;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          ctx.commit("clearSession");
          return 403;
        }
        return e.response ? e.response.status : 999;
      }
    },
    async loadZoneTypes(ctx) {
      try {
        const res = await getZoneTypes();
        ctx.commit("setZoneTypes", res.data);
        return 0;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          ctx.commit("clearSession");
          return 403;
        }
        return e.response ? e.response.status : 999;
      }
    },
    async loadDrivers(ctx) {
      try {
        const res = await getDrivers();
        ctx.commit("setDrivers", res.data);
        return 0;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          ctx.commit("clearSession");
          return 403;
        }
        return e.response ? e.response.status : 999;
      }
    },
    async loadEvents(ctx) {
      try {
        const nextId = ctx.state.events.reduce((a, c) => Math.max(a, c.id), 0);
        const res = await getEvents(nextId);
        ctx.commit("updateEvents", res.data);
        return 0;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          ctx.commit("clearSession");
          return 403;
        }
        return e.response ? e.response.status : 999;
      }
    },
    async loadReports(ctx) {
      try {
        const res = await getUserReports();
        ctx.commit("setReports", res.data);
        return 0;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          ctx.commit("clearSession");
          return 403;
        }
        return e.response ? e.response.status : 999;
      }
    },
    async loadDescriptions(ctx) {
      try {
        const res = await getVehicleDescriptions();
        ctx.commit("setDescriptions", res.data);
        return 0;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          ctx.commit("clearSession");
          return 403;
        }
        return e.response ? e.response.status : 999;
      }
    },
    async loadWaybills(ctx) {
      try {
        const res = await getWaybillList();
        ctx.commit("setWaybills", res.data);
        return 0;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          ctx.commit("clearSession");
          return 403;
        }
        return e.response ? e.response.status : 999;
      }
    },
    async loadWaybillPlan(ctx) {
      try {
        const res = await getRouteSheet();
        ctx.commit("setWaybillPlan", res.data);
        return 0;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          ctx.commit("clearSession");
          return 403;
        }
        return e.response ? e.response.status : 999;
      }
    },
    async loadReportStore(ctx) {
      try {
        const res = await getReportsV2Store();
        ctx.commit("setReportStore", res.data);
        return 0;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          ctx.commit("clearSession");
          return 403;
        }
        return e.response ? e.response.status : 999;
      }
    },
  },
  getters: {
    authorized(state) {
      return state.username.length > 0 && state.session !== null;
    },
    mapProvider(state) {
      if (!state.settings.map) {
        return "";
      }
      // Process with legacy naming
      const legacy = state.settings.map.charAt(0);
      switch (legacy) {
        case "O":
          return "osm";
        case "G":
          return "google";
        default: // New naming
          return state.settings.map.split(":")[0];
      }
    },
    mapLayer(state) {
      if (!state.settings.map) {
        return "";
      }
      // Process with legacy naming
      const legacy = state.settings.map.charAt(0);
      switch (legacy) {
        case "O":
          return "standard";
        case "G":
          return state.settings.map.substring(6).toLowerCase();
        default: // New naming
          if (state.settings.map.substring(0, 6) === "google") {
            let layer = state.settings.map.split(":")[1];
            if (["roadmap", "satellite", "terrain", "hybrid"].includes(layer)) {
              return layer;
            } else {
              return "roadmap";
            }
          }
          return state.settings.map.split(":")[1];
      }
    },
  },
});

export function getSettingFallback(obj, field, defaultValue) {
  if (field in obj) {
    let val = obj[field];
    if (val === null) {
      return defaultValue;
    }
    return val;
  }
  return defaultValue;
}

export function getSetting(field, defaultValue) {
  return getSettingFallback(store.state.settings, field, defaultValue);
}
