export const AUTO_TOKEN_KEY = "%nt:token";

/**
 * Get saved token to resume session
 *
 * @returns Token string or null
 */
export function restoreAuth() {
  const token = localStorage.getItem(AUTO_TOKEN_KEY);
  return token || null;
}

/**
 * Save token for future resuming session
 *
 * @param {String} token Token string
 */
export function saveAuth(token) {
  localStorage.setItem(AUTO_TOKEN_KEY, token);
}

/**
 * Convert deg to rad
 *
 * @param {Number} val Angle in degrees
 * @returns Angle in rad
 */
function convertToRadian(val) {
  return (val * Math.PI) / 180;
}

/**
 * Calculates polygon area
 *
 * @param {Array<{latitude: Number, longitude: Number}>} path Array of points
 * @param {Boolean} loop Loop array (copy first point)
 * @returns Area in m
 */
export function calculatePolygonArea(path, loop = false) {
  if (loop) {
    path = [...path, path[0]];
  }
  let area = 0;
  try {
    if (path.length > 2) {
      for (let i = 0; i < path.length - 1; i++) {
        const p1 = path[i];
        const p2 = path[i + 1];
        area +=
          convertToRadian(p2.longitude - p1.longitude) *
          (2 +
            Math.sin(convertToRadian(p1.latitude)) +
            Math.sin(convertToRadian(p2.latitude)));
      }
      area = (area * 6378137 * 6378137) / 2;
    }
  } catch (e) {
    console.warn(e);
  }
  return Math.abs(area);
}

/**
 * Format phone number with spaces
 * +380000000000 -> +38 000 000 00 00
 *
 * @param {String} num Phone number without spaces
 * @returns Formatted phone number
 */
export function formatPhone(num) {
  return num.replace(
    /\+?(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})/,
    "+$1 $2 $3 $4 $5",
  );
}

/**
 * List of available map providers
 */
export const MAP_TYPES = [
  {
    name: "Google Normal",
    value: "google:roadmap",
    ok: true,
  },
  {
    name: "Google Satellite",
    value: "google:satellite",
    ok: true,
  },
  {
    name: "Google Terrain",
    value: "google:terrain",
    ok: true,
  },
  {
    name: "Google Hybrid",
    value: "google:hybrid",
    ok: true,
  },
  {
    name: "OpenStreetMap",
    value: "osm:standard",
    ok: true,
  },
  {
    name: "Here Day",
    value: "here:explore.day",
    ok: false,
  },
  {
    name: "Here Night",
    value: "here:explore.day",
    ok: false,
  },
  {
    name: "Here Satellite Day",
    value: "here:explore.satellite.day",
    ok: false,
  },
  {
    name: "Here Lite Day",
    value: "here:lite.day",
    ok: false,
  },
  {
    name: "Here Lite Night",
    value: "here:lite.night",
    ok: false,
  },
  {
    name: "Here Lite Satellite Day",
    value: "here:lite.satellite.day",
    ok: false,
  },
  {
    name: "Here Logistics Day",
    value: "here:logistics.day",
    ok: false,
  },
  {
    name: "Here Logistics Night",
    value: "here:logistics.night",
    ok: false,
  },
  {
    name: "Here Logistics Satellite Day",
    value: "here:logistics.satellite.day",
    ok: false,
  },
  {
    name: "Here Satellite Day",
    value: "here:satellite.day",
    ok: false,
  },
  {
    name: "Jawg Sunny",
    value: "jawg:sunny",
    ok: false,
  },
  {
    name: "Jawg Street",
    value: "jawg:street",
    ok: false,
  },
  {
    name: "Mapbox Street",
    value: "mbox:street",
    ok: false,
  },
  {
    name: "Mapbox Satellite",
    value: "jawg:street",
    ok: false,
  },
  {
    name: "Maptiler",
    value: "mtiler:street",
    ok: false,
  },
  {
    name: "TomTom Basic",
    value: "ttom:basic",
    ok: false,
  },
  {
    name: "TomTom Hybrid",
    value: "ttom:hybrid",
    ok: false,
  },
  {
    name: "StadiaMaps Bright",
    value: "smaps:bright",
    ok: true,
  },
  {
    name: "StadiaMaps Outdoor",
    value: "smaps:outdoor",
    ok: true,
  },
  {
    name: "CartoDB Voyager",
    value: "carto:voyager",
    ok: true,
  },
];

/**
 * Returns time since timestamp in human a readable form
 *
 * @param t Translation function
 * @param date Timestamp
 * @returns {string}
 */
export function timeSince(t, date) {
  const seconds = Math.floor((Date.now() - date) / 1000);
  let interval = seconds / 31536000;
  if (interval > 1) {
    return t("time-ago.year", Math.floor(interval));
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return t("time-ago.month", Math.floor(interval));
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return t("time-ago.day", Math.floor(interval));
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return t("time-ago.hour", Math.floor(interval));
  }
  interval = seconds / 60;
  if (interval > 1) {
    return t("time-ago.minute", Math.floor(interval));
  }
  if (interval > 0) {
    return t("time-ago.second", Math.floor(interval));
  }
  return t("time-ago.second", 0);
}

/**
 * Convert seconds to the human-readable time span
 *
 * @param t Translation functions
 * @param stamp Amount of seconds
 * @returns {string}
 */
export function timeSpan(t, stamp) {
  const span = stamp / 1000;
  let interval = span / 31536000;
  let result = "";
  if (interval > 1) {
    result = t("time-span.year", Math.floor(interval));
  }
  interval = (span % 31536000) / 2592000;
  if (interval > 1) {
    result += " " + t("time-span.month", Math.floor(interval));
  }
  interval = (span % 2592000) / 86400;
  if (interval > 1) {
    result += " " + t("time-span.day", Math.floor(interval));
  }
  interval = (span % 86400) / 3600;
  if (interval > 1) {
    result += " " + t("time-span.hour", Math.floor(interval));
  }
  interval = (span % 3600) / 60;
  if (interval > 1) {
    result += " " + t("time-span.minute", Math.floor(interval));
  }
  return result.trim();
}

/**
 * Event identification
 */
export const EventID = {
  Parking: 0,
  EnterZone: 2,
  LeaveZone: 3,
  Speeding: 9,
  FuelCharge: 11,
  FuelDrain: 12,
  FindSatellites: 4,
  LossSatellites: 5,
  StopEngine: 15,
  StartEngine: 16,
  ChangeDriver: 19,
  HullUp: 7783,
  HullDown: 7782,
};

/**
 * Track colors lst
 */
export const TrackColors = [
  {
    solid: "#4876ff",
    gradient:
      "linear-gradient(0deg, rgba(72,118,255,1) 0%, rgba(72,118,255,1) 29%, rgba(122,155,251,1) 59%, rgba(72,118,255,1) 100%)",
    reverse: "#ff9748",
  },
  {
    solid: "#cd4f39",
    gradient:
      "linear-gradient(0deg, rgba(205,79,57,1) 0%, rgba(205,79,57,1) 29%, rgba(233,111,90,1) 59%, rgba(205,79,57,1) 100%)",
    reverse: "#39cd92",
  },
  {
    solid: "#00ced1",
    gradient:
      "linear-gradient(0deg, rgba(0,206,209,1) 0%, rgba(0,206,209,1) 29%, rgba(17,233,236,1) 59%, rgba(0,206,209,1) 100%)",
    reverse: "#d13400",
  },
  {
    solid: "#cd69c9",
    gradient:
      "linear-gradient(0deg, rgba(205,105,201,1) 0%, rgba(205,105,201,1) 29%, rgba(232,122,228,1) 59%, rgba(205,105,201,1) 100%)",
    reverse: "#cacd69",
  },
  {
    solid: "#698b22",
    gradient:
      "linear-gradient(0deg, rgba(105,139,34,1) 0%, rgba(105,139,34,1) 29%, rgba(127,167,43,1) 59%, rgba(105,139,34,1) 100%)",
    reverse: "#8b226a",
  },
  {
    solid: "#00c78c",
    gradient:
      "linear-gradient(0deg, rgba(0,199,140,1) 0%, rgba(0,199,140,1) 29%, rgba(23,227,167,1) 59%, rgba(0,199,140,1) 100%)",
    reverse: "#c72400",
  },
  {
    solid: "#8a3324",
    gradient:
      "linear-gradient(0deg, rgba(138,51,36,1) 0%, rgba(138,51,36,1) 29%, rgba(187,64,43,1) 59%, rgba(138,51,36,1) 100%)",
    reverse: "#248a60",
  },
  {
    solid: "#ffa500",
    gradient:
      "linear-gradient(0deg, rgba(255,165,0,1) 0%, rgba(255,165,0,1) 29%, rgba(250,189,76,1) 59%, rgba(255,165,0,1) 100%)",
    reverse: "#4d00ff",
  },
];

/**
 * Loop between dates with 00:00:00 dissection
 * @param start start date
 * @param end end date
 * @param call callback
 */
export function dateLoop(start, end, call) {
  let left = start;
  let right = new Date(start);
  right.setHours(23, 59, 59, 999);
  right.setMilliseconds(right.getMilliseconds() + 1);
  do {
    call(left, right);
    left = right;
    right = new Date(right);
    right.setDate(right.getDate() + 1);
  } while (end > right);
  call(left, end);
}

/**
 * Calculate distance between to points
 *
 * @param point1 Point {lat, lng}
 * @param point2 Point {lat, lng}
 * @returns {number} Distance in m
 */
export function calculateDistance2Points(point1, point2) {
  const R = 6371e3; // metres
  const f1 = (point1.lat * Math.PI) / 180; // φ, λ in radians
  const f2 = (point2.lat * Math.PI) / 180;
  const df = ((point2.lat - point1.lat) * Math.PI) / 180;
  const dg = ((point2.lng - point1.lng) * Math.PI) / 180;

  const a =
    Math.sin(df / 2) * Math.sin(df / 2) +
    Math.cos(f1) * Math.cos(f2) * Math.sin(dg / 2) * Math.sin(dg / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // in metres
}

/**
 * Calculate distance of path
 *
 * @param path Path (list of points {lat, lng}
 * @returns {{total: number, lines: [number]}} Total distance and each line
 */
export function calculatePathDistance(path) {
  let total = 0;
  const lines = [];

  for (let i = 1; i < path.length; i++) {
    const line = calculateDistance2Points(path[i - 1], path[i]);
    total += line;
    lines.push(line);
  }

  return {
    total,
    lines,
  };
}

/**
 * Calculate azimuth from two points
 *
 * @param point1 Point {lat, lng}
 * @param point2 Point {lat, lng}
 * @returns {number} Direction (0-359)
 */
export function calculateAzimuth(point1, point2) {
  if (!point1 || !point2) {
    return 0;
  }
  console.debug("[Utils] Azimuth", point1, point2);
  const f1 = (point1.lat * Math.PI) / 180; // φ, λ in radians
  const f2 = (point2.lat * Math.PI) / 180;
  const delta = ((point2.lng - point1.lng) * Math.PI) / 180;
  const y = Math.sin(delta) * Math.cos(f2);
  const x =
    Math.cos(f1) * Math.sin(f2) - Math.sin(f1) * Math.cos(f2) * Math.cos(delta);
  const az = Math.atan2(y, x);
  return (az * (180 / Math.PI) + 360) % 360;
}

/**
 * Format amount of seconds as time span (00:00:00)
 *
 * @param time Amount of seconds
 * @returns {string}
 */
export function secondsToSpan(time) {
  let timeStr = "";
  timeStr += ("0" + Math.floor(time / 3600)).slice(-2) + ":";
  timeStr += ("0" + Math.floor((time % 3600) / 60)).slice(-2) + ":";
  timeStr += ("0" + Math.floor(time % 60)).slice(-2);
  return timeStr;
}

/**
 * Extract path from track to short speeding tracks
 *
 * @param track Track
 * @returns Paths object
 */
export function extractSpeedingTrack(track) {
  const paths = [];
  let path = { speeding: false, points: [], id: 0 };
  for (let i = 0; i < track.path.length - 1; i++) {
    if (track.path[i].speeding && track.path[i + 1].speeding) {
      if (path.speeding) {
        path.points.push(track.path[i]);
      } else {
        path.points.push(track.path[i]);
        paths.push(path);
        path = { speeding: true, points: [track.path[i]], id: path.id + 1 };
      }
    } else {
      if (path.speeding) {
        path.points.push(track.path[i]);
        paths.push(path);
        path = { speeding: false, points: [track.path[i]], id: path.id + 1 };
      } else {
        path.points.push(track.path[i]);
      }
    }
  }
  path.points.push(track.path[track.path.length - 1]);
  paths.push(path);
  console.debug(
    "[Utils] Source path has",
    track.path.length,
    " points, splits have",
    paths.reduce((a, c) => a + c.points.length, 0),
  );
  return paths;
}

/**
 * Track details columns
 */
export const TrackDetailsColumns = [
  { key: "SPEED", name: "speed", prop: (o) => o.speed },
  {
    key: "IGNITION",
    name: "ignite",
    prop: (o, t) => t("label." + (o.ignition ? "on" : "off")),
  },
  { key: "SATELLITES", name: "satellites", prop: (o) => o.dsats },
  { key: "TEMPERATURE", name: "temperature", prop: (o) => o.temperature },
  { key: "DISTANCE", name: "distance", prop: (o) => o.ddist },
  { key: "FUEL_TOTAL", name: "fuel", prop: (o) => o.fuel },
  { key: "FUEL1", name: "fuel1", prop: (o) => o.fuel1 },
  { key: "FUEL2", name: "fuel2", prop: (o) => o.fuel2 },
  { key: "FUEL3", name: "fuel3", prop: (o) => o.fuel3 },
  {
    key: "ALARM",
    name: "sos",
    prop: (o, t) => t("label." + (o.sos ? "on" : "off")),
  },
  {
    key: "DOORS",
    name: "doors",
    prop: (o, t) => t("label." + (o.doors ? "open" : "closed")),
  },
  {
    key: "HOU",
    name: "hou",
    prop: (o, t) => t("label." + (o.hou ? "on" : "off")),
  },
  { key: "INNER_VOLTAGE", name: "battery", prop: (o) => o.innerVoltage },
  { key: "OUTER_VOLTAGE", name: "power", prop: (o) => o.outerVoltage },
  {
    key: "FUEL_SENSOR_TEMP",
    name: "fuel-sensor-temp",
    prop: (o) =>
      JSON.parse(o.fuelSensorTemp || '["-"]')
        .map((v) => Object.values(v)[0])
        .join(";"),
  },
  {
    key: "FUEL_SENSOR_VOLTAGE",
    name: "fuel-sensor-volt",
    prop: (o) =>
      JSON.parse(o.fuelSensorVoltage || '["-"]')
        .map((v) => Object.values(v)[0])
        .join(";"),
  },
];

/**
 * Default track details columns
 */
export const TrackDetailsDefaultColumns =
  "SPEED,IGNITION,SATELLITES,TEMPERATURE,DISTANCE,FUEL_TOTAL,ALARM,DOORS,HOU";

/**
 * Convert camelCase to kebab-case
 * @param str
 * @returns {*}
 */
export function kebabCase(str) {
  return str
    .split("")
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? "-" : ""}${letter.toLowerCase()}`
        : letter;
    })
    .join("");
}

/**
 * Columns that need to be described as fuel level
 *
 * @type {string[]}
 */
export const ReportFuelLevelColumns = [
  "startFuelLevel",
  "endFuelLevel",
  "fuelConsumption",
  "fuelChargeSum",
  "fuelDrainSum",
  "differenceFuelLevel",
  "fuelBeforeStop",
  "fuelAfterStop",
  "totalFuelConsumption",
  "averageFuelConsumptionPer100Km",
  "fuelCharge",
  "fuelDrain",
  "fuelByCAN",
  "fuelConsumptionNorm",
  "fuelStart",
  "fuelStop",
  "fuelDifference",
];

/**
 * Columns that need to be described as speed
 *
 * @type {string[]}
 */
export const ReportSpeedColumns = [
  "maxSpeed",
  "averageSpeed",
  "averageSpeedOnMove",
  "maxSpeedLimit",
];

/**
 * Columns that need to be described as fuel used over 100km
 *
 * @type {string[]}
 */
export const ReportFuelDistanceColumns = ["fuelConsumptionPer100km"];

/**
 * Columns that need to be described as fuel used over hour
 *
 * @type {string[]}
 */
export const ReportFuelTimeColumns = ["fuelConsumptionPerHour"];

/**
 * Columns that need to be described as time
 *
 * @type {string[]}
 */
export const ReportSpeedTimeColumns = ["speedingInterval"];

/**
 * Columns that need to be described as distamce
 *
 * @type {string[]}
 */
export const ReportDistanceColumns = [
  "distance",
  "summingDistance",
  "distanceByCAN",
  "distanceStart",
  "distanceStop",
  "distanceDifference",
];

/**
 * Available chart types
 */
// May be planned: CAN_MOTO, ALTITUDE, SATELLITES, DOORS, HOU, ALARM
export const ChartTypeList = [
  {
    value: 1,
    text: "ignite",
    key: "IGNITION",
  },
  {
    value: 2,
    text: "speed",
    key: "SPEED",
  },
  {
    value: 3,
    text: "fuel",
    key: "FUEL_TOTAL",
  },
  {
    value: 4,
    text: "temperature",
    key: "TEMPERATURE",
  },
  {
    value: 5,
    text: "fuel1",
    key: "FUEL1",
  },
  {
    value: 6,
    text: "fuel2",
    key: "FUEL2",
  },
  {
    value: 7,
    text: "fuel3",
    key: "FUEL3",
  },
  {
    value: 8,
    text: "can-temperature",
    key: "CAN_TEMP",
  },
  {
    value: 9,
    text: "can-rounds",
    key: "CAN_ENGINE_ROUNDS",
  },
  {
    value: 16,
    text: "power-supply",
    key: "OUTER_VOLTAGE",
  },
];

/**
 * Human-readable number
 *
 * @param x Number
 * @returns {string}
 */
export function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const universalBOM = "\uFEFF";

/**
 * Generate CSV file with ';' as separator
 *
 * @param header Array of columns
 * @param data Array of rows (rows are array of cells)
 * @returns {string} DataURL
 */
export function generateCSV(header, data) {
  header = header.map((c) => `"${c}"`).join(";");
  data = data.map((row) => row.map((cell) => `"${cell}"`).join(";")).join("\n");
  return (
    "data:text/csv;charset=utf-8," +
    encodeURIComponent(universalBOM + header + "\n" + data)
  );
}

/**
 * Download given CSV dataURL
 *
 * @param uri DataURL
 * @param name file name
 */
export function downloadCSV(uri, name) {
  const link = document.createElement("a");
  link.setAttribute("href", uri);
  link.setAttribute("download", name + ".csv");
  document.body.appendChild(link);
  link.click();
  setTimeout(() => document.body.removeChild(link), 60000);
}

/**
 * Calculate center of polygon
 *
 * @param points List of points { lat, lng }
 * @returns {{lng: number, lat: number}}
 */
export function centerOfPolygon(points) {
  let x = 0,
    y = 0,
    f,
    point1,
    point2;

  for (let i = 0, j = points.length - 1; i < points.length; j = i, i++) {
    point1 = points[i];
    point2 = points[j];
    f = point1.lat * point2.lng - point2.lat * point1.lng;
    x += (point1.lat + point2.lat) * f;
    y += (point1.lng + point2.lng) * f;
  }

  f = polygonArea(points) * 6;

  console.debug("[Utils] Center", { x, y }, f, { lat: x / f, lng: y / f });

  return { lat: x / f, lng: y / f };
}

function polygonArea(points) {
  let area = 0,
    point1,
    point2;

  for (let i = 0, j = points.length - 1; i < points.length; j = i, i++) {
    point1 = points[i];
    point2 = points[j];
    area += point1.lat * point2.lng;
    area -= point1.lng * point2.lat;
  }
  area /= 2;

  return area;
}

export const DEFAULT_DASHBOARD_STATE =
  "online,state,inZone,events,mileage,fuel,topMileage,topFuel;;;";

/**
 * Debounce calling function for given delay
 * @param mainFunction Function to debounce
 * @param delay Delay in milliseconds
 */
export function debounce(mainFunction, delay) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      mainFunction(...args);
    }, delay);
  };
}

/**
 * Throttle calling function for give delay
 * @param mainFunction Function to throttle
 * @param delay Delay in milliseconds
 * @returns {(function(...[*]): void)|*}
 */
export function throttle(mainFunction, delay) {
  let timerFlag = null; // Variable to keep track of the timer

  // Returning a throttled version
  return (...args) => {
    if (timerFlag === null) { // If there is no timer currently running
      mainFunction(...args); // Execute the main function
      timerFlag = setTimeout(() => { // Set a timer to clear the timerFlag after the specified delay
        timerFlag = null; // Clear the timerFlag to allow the main function to be executed again
      }, delay);
    }
  };
}


export const ReportDocumentCellType = {
  String: 0, // Nothing to add
  Date: 1, // Format as date without time
  DateTime: 2, // Format as date with time
  TimeSpan: 3, // Format at time 00:00:00 (hours possible more than 24)
  Speed: 4, // Add km/h at end
  Distance: 5, // Add km at end
  Temperature: 6, // Add C at enc
  FuelConsumptionDistance: 7, // Add L/100km at end
  FuelConsumptionTime: 8, // Add L/Hour at end,
  FuelLevel: 9, // Add L at end
  Number: 10, // Nothing to add, right aligned
  Level: 11, // Add mm at end
  Density: 12, // Add kg/m3 at end
  Volume: 13, // Add m3 at end
  Weight: 14, // Add T at end
  Area: 15, // Add ha at end
  CoordinateLatitude: 16, // Create link
  CoordinateLongitude: 17, // Create link
  Vehicle: 18, // Used as reference
};

export function timeSpanReg(stamp) {
  stamp /= 1000;
  let s = Math.floor(stamp % 60);
  let m = Math.floor(stamp / 60) % 60;
  let h = Math.floor(stamp / 3600);
  if (s < 10) {
    s = "0" + s;
  }
  if (m < 10) {
    m = "0" + m;
  }
  if (h < 10) {
    h = "0" + h;
  }
  return `${h}:${m}:${s}`;
}
