<script setup>
import {
  computed,
  defineAsyncComponent,
  provide,
  onMounted,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  SuiMenu,
  SuiMenuItem,
  SuiMenuMenu,
  SuiDimmer,
  SuiLoader,
  SuiDropdown,
  SuiDropdownMenu,
  SuiDropdownItem,
  SuiDivider,
  SuiIcon,
} from "@symbx/vue-fomantic-ui";
import { getNotifications, Rights } from "@/api";
import "fomantic-ui-css/components/menu.min.css";
import "fomantic-ui-css/components/dimmer.min.css";
import "fomantic-ui-css/components/loader.min.css";
import "fomantic-ui-css/components/dropdown.min.css";
import "fomantic-ui-css/components/transition.min.css";
import "fomantic-ui-css/components/divider.min.css";
import "fomantic-ui-css/components/icon.min.css";
import "fomantic-ui-css/components/toast.min.css";
import ConfirmDialog from "@/dialogs/confirm-dialog.vue";
import { AUTO_TOKEN_KEY } from "@/utils.js";
import { useI18n } from "vue-i18n";
import { getSetting } from "@/store.js";
import PromptDialog from "@/dialogs/prompt-dialog.vue";
const NotificationMessage = defineAsyncComponent(() =>
  import("@/dialogs/notification-message.vue"),
);
const SettingsDialog = defineAsyncComponent(() =>
  import("@/dialogs/settings-dialog.vue"),
);
const MaintenanceDialog = defineAsyncComponent(() =>
  import("@/dialogs/maintenance-dialog.vue"),
);
const FuelFillingDialog = defineAsyncComponent(() =>
  import("@/dialogs/fuel-filling-dialog.vue"),
);
const WaybillDialog = defineAsyncComponent(() =>
  import("@/dialogs/waybill-dialog.vue"),
);

const store = useStore();
const { t, locale } = useI18n();
const router = useRouter();
const loader = ref(true);
const authorized = computed(() => store.getters.authorized);
const username = computed(() => store.state.username);
const routing = ref(false);
const hasReports = computed(
  () =>
    store.state.rights.includes(Rights.ReadReports) ||
    store.state.rights.includes(Rights.PrintReports),
);
const hasWaybills = computed(
  () =>
    store.state.rights.includes(Rights.WaybillManage) ||
    store.state.rights.includes(Rights.WaybillView),
);
const dialogs = ref({
  settings: false,
  maintenance: false,
  fuelFilling: false,
  waybill: false,
});
const notifications = ref([]);
const notificationTray = computed(() =>
  notifications.value.filter((n) => !n.open),
);
setTimeout(() => (loader.value = false), 10);

async function loadNotifications() {
  try {
    const resp = await getNotifications();
    notifications.value = resp.data.map((n) => ({
      ...n,
      open: false,
    }));
  } catch (e) {
    console.error("[App] Failed to load notifications", e);
  }
}

watch(
  () => store.getters.authorized,
  () => {
    console.debug(
      "[App] Authorization state changed",
      store.getters.authorized,
    );
    if (store.getters.authorized) {
      router.replace({
        name: "map",
      });
      setTimeout(() => loadNotifications(), 2500);
    } else {
      router.replace({
        name: "auth",
      });
    }
  },
);

watch(
  () => store.state.settings,
  () => {
    locale.value = getSetting("locale", "uk");
  },
);

router.beforeEach(() => {
  routing.value = true;
});

router.afterEach(() => {
  routing.value = false;
});

const confirmDialog = ref(null);
const promptDialog = ref(null);
onMounted(() => {
  provide("$confirm", confirmDialog.value.open);
  provide("$prompt", promptDialog.value.open);
});

async function quit() {
  if (confirmDialog.value) {
    try {
      await confirmDialog.value.open(
        t("msg.quit"),
        t("action.cancel"),
        t("action.quit"),
        null,
        true,
      );
    } catch (e) {
      if (!e) {
        localStorage.removeItem(AUTO_TOKEN_KEY);
        location.reload();
      }
    }
  }
}

const ATB = !!import.meta.env.VITE_ATB;

const currentNotification = ref(null);
function openNotification(n) {
  const notification = notifications.value.find((val) => val.id === n.id);
  if (notification) {
    notification.open = true;
    currentNotification.value = n;
  }
}
</script>

<template>
  <transition name="loader">
    <div class="loader-catch" v-if="loader"></div>
  </transition>
  <sui-menu
    fixed
    v-if="authorized && !routing"
    compact
    borderless
    class="main-menu"
  >
    <sui-menu-item class="logo-header static">
      <img
        src="@/assets/imgs/logo.webp"
        style="width: 24px"
        class="logo"
        alt="Navitrack"
        title="Navitrack"
      />
      Navitrack
    </sui-menu-item>
    <router-link class="item" :to="{ name: 'dashboard' }" exact>{{
      $t("label.dashboard")
    }}</router-link>
    <router-link class="item" :to="{ name: 'map' }" exact>{{
      $t("label.map")
    }}</router-link>
    <router-link class="item" v-if="hasReports" :to="{ name: 'reports' }">{{
      $t("label.reports")
    }}</router-link>
    <router-link
      class="item"
      v-if="hasWaybills && !ATB"
      :to="{ name: 'waybill' }"
      >{{ $t("label.waybills") }}</router-link
    >
    <sui-menu-menu position="right">
      <sui-dropdown :text="username" class="item bolder">
        <sui-dropdown-menu>
          <sui-dropdown-item
            icon="book"
            :text="$t('label.maintenance')"
            @click="dialogs.maintenance = true"
          />
          <sui-dropdown-item
            icon="theme"
            :text="$t('label.fuel-filling')"
            @click="dialogs.fuelFilling = true"
          />
          <sui-dropdown-item
            icon="route"
            :text="$t('label.waybill-x')"
            @click="dialogs.waybill = true"
          />
          <!--suppress HtmlUnknownTarget -->
          <a class="icon item" href="/docs/manual_uk.pdf" target="_blank">
            <sui-icon name="question mark" />
            {{ $t("label.manual") }}
          </a>
          <sui-divider />
          <sui-dropdown-item
            icon="cog"
            :text="$t('label.settings')"
            @click="dialogs.settings = true"
          />
          <sui-dropdown-item
            icon="sign out"
            :text="$t('label.quit')"
            @click="quit"
          />
        </sui-dropdown-menu>
      </sui-dropdown>
    </sui-menu-menu>
  </sui-menu>
  <div class="page" :class="{ menu: authorized && !routing }">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
  <sui-dimmer :active="routing" inverted>
    <sui-loader active />
  </sui-dimmer>
  <confirm-dialog ref="confirmDialog" />
  <prompt-dialog ref="promptDialog" />
  <settings-dialog v-model:visible="dialogs.settings" v-if="dialogs.settings" />
  <maintenance-dialog
    v-model:visible="dialogs.maintenance"
    v-if="dialogs.maintenance"
  />
  <fuel-filling-dialog
    v-model:visible="dialogs.fuelFilling"
    v-if="dialogs.fuelFilling"
  />
  <waybill-dialog v-model:visible="dialogs.waybill" v-if="dialogs.waybill" />
  <div class="top right ui toast-container">
    <div
      class="floating toast-box compact transition visible"
      v-for="n in notificationTray"
    >
      <div
        class="neutral ui toast compact visible"
        @click="openNotification(n)"
      >
        <sui-icon :name="n.icon" v-if="n.icon" />
        <div class="content" v-text="n.titleUA" v-if="locale === 'uk'" />
        <div class="content" v-text="n.titleRU" v-if="locale === 'ru'" />
        <div class="content" v-text="n.titleEN" v-if="locale === 'en'" />
        <sui-icon name="close" @click.stop.prevent="n.open = true" />
      </div>
    </div>
  </div>
  <notification-message
    v-if="currentNotification !== null"
    v-model="currentNotification"
  />
</template>

<style scoped>
/*noinspection CssUnusedSymbol*/
.loader-enter-active,
.loader-leave-active {
  transition: opacity 0.5s ease;
}

/*noinspection CssUnusedSymbol*/
.loader-enter-from,
.loader-leave-to {
  opacity: 0;
}
.page {
  height: 100%;
}

/*noinspection CssUnusedSymbol*/
.page.menu {
  height: calc(100% - 45px);
  margin-top: 45px;
}
.main-menu {
  z-index: 1001 !important;
}
.main-menu > .item {
  font-size: 14px !important;
  font-weight: 700 !important;
}
.main-menu > .item {
  background: none;
  border-bottom: 3px solid transparent;
}

/*noinspection CssUnusedSymbol*/
.main-menu > .item.active {
  background: none;
  border-bottom: 3px solid #9ab700;
}
.main-menu > .logo-header {
  font-size: 1.4em !important;
  padding: 0 10px;
}
.main-menu > .logo-header > img {
  margin-right: 4px !important;
}
.toast-container {
  top: 54px !important;
  z-index: 1000;
}
</style>
